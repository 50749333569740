import {
    handleQueryResolve
} from '../utils'

// PatientID, GenericName = '', Strength = '', extendedWhere = 'rx.RxDate > DATEADD(month, -6, GetDate())'
export default function (GroupID) {
  // FIXME - need to set this up to work with the query for the blister pack
  if (GroupID) {
    const attrs = [{
      param: 'GroupID',
      type: 'sql.Int',
      value: GroupID, // to support like
    }]
    const where = ['GroupID = @GroupID']

    return this.query(`
        SELECT  
        Patient.PatientID as PatientID
              ,[LastName]
              ,[FirstName],
              GroupID,
              FORMAT (DOB, 'dd/MM/yyyy ') as Birthday
          FROM [Fillware].[dbo].[Patient]
          INNER JOIN Fillware.dbo.PatientGroup ON Fillware.dbo.Patient.PatientID = Fillware.dbo.PatientGroup.PatientID
          WHERE ${where}
        `, attrs)
      .then(handleQueryResolve)
  }
}
